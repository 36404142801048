import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons'

const CarouselItem = ({ forms }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextPage = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + 1 < forms.length ? prevIndex + 1 : 0
    )
  }

  const prevPage = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex - 1 >= 0 ? prevIndex - 1 : forms.length - 1
    )
  }

  const CurrentForm = forms[currentIndex]

  return (
    <Row>
      <Col lg={1} className='d-flex align-items-center justify-content-center'>
        <div className='arrow-carousel' onClick={prevPage}>
          <ArrowLeftCircle size={24} />
        </div>
      </Col>
      <Col lg={10}>
        <CurrentForm />
      </Col>
      <Col lg={1} className='d-flex align-items-center justify-content-center'>
        <div className='arrow-carousel' onClick={nextPage}>
          <ArrowRightCircle size={24} />
        </div>
      </Col>
    </Row>
  )
}

export default CarouselItem