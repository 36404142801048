// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('useEffect 1')
      setUser({ token }); // Pode ser um objeto de usuário mais completo
    } else {
      setUser(null)
    }
    setLoading(false)
  }, []);

  const login = (username, password) => {
    if (username === 'admin' && password === '123') {
      const token = 'fake-token';
      localStorage.setItem('token', token);
      setUser({ token })
      navigate('/')
    } else {
      alert('Login inválido');
    }
  };

  const logout = () => {
    localStorage.removeItem('token')
    setUser(null)
    navigate('/login')
  };

  const isAuthenticated = () => {
    return !!user;
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
