import React, { useState } from 'react'
import ImageUploading from 'react-images-uploading'

import ImageUploadingComponent from './imageUploadingComponent'

import { XCircle } from 'react-bootstrap-icons'

const SlideImages = ({ data }) => {
  const [images, setImages] = React.useState([])
  const maxNumber = 69

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div className="App">
      <ImageUploadingComponent />
    </div>
  );
}

export default SlideImages