import React from 'react'
import { Accordion } from 'react-bootstrap'
import ImageUploadingComponent from './imageUploadingComponent'

const Photos = ({ data }) => {
  return (
    <ImageUploadingComponent />
  )
}

export default Photos