import React from 'react'
import CarouselItem from './carouselItem'
import FormComponent from './formComponent'

const Highlights = () => {
  const Form1 = () => (
    <FormComponent data={{ id: 1 }} />
  )
    
  const Form2 = () => (
    <FormComponent data={{ id: 2 }} />
  );
  
  const Form3 = () => (
    <FormComponent data={{ id: 3 }} />
  );

  const forms = [Form1, Form2, Form3];
  
  return (
    <CarouselItem forms={forms} />
  )
}

export default Highlights