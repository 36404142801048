import React, { useState, useEffect, useContext } from 'react'
import { Col, ListGroup, Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import { House, FileEarmark, Gear, PersonCircle, MenuButton, PersonGear } from 'react-bootstrap-icons'
// import { logout } from '../services/authenticate'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../services/AuthContext'

const LeftSideMenu = ({ tamanho }) => {
  const navigate = useNavigate()
  const { logout } = useContext(AuthContext)
  const [tamanhoMenu, setTamanhoMenu] = useState(0)
  const [ocultarMenu, setOcultarMenu] = useState(false)

  const handleLogout = async () => {
    logout(navigate)
  }

  useEffect(() => {
    setOcultarMenu(false)
    if (tamanho === 0) {
        setOcultarMenu(true)
    }
    setTamanhoMenu(tamanho)
  }, [tamanho])

    const menu = [
        {
            name: 'Página inicial',
            content: '#home',
            icon: <House />
        },
        {
            name: 'Páginas',
            content: '#pages',
            icon: <FileEarmark />
        },
        {
            name: 'Usuários',
            content: '#users',
            icon: <PersonGear />
        },
        {
            name: 'Configurações',
            content: '#settings',
            icon: <Gear />
        }
    ]

    return (
        <Col xs={tamanhoMenu} className='menu' style={{  display: ocultarMenu ? 'none' : '' }}>
            <ListGroup style={{ marginTop: '60px' }}>
                { menu.map((element, index) => (
                    <ListGroup.Item key={index} action href={element.content} variant="light">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {element.icon}
                            <span className='lexend' style={{ marginLeft: '8px' }}>{element.name}</span>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Dropdown as={ButtonGroup} style={{ width: '300px', position: 'absolute', bottom: '10px' }}>
                <Button variant="secondary">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PersonCircle /> 
                        <span className='lexend' style={{ marginLeft: '8px' }}>lucas.2601@gmail.com</span>
                    </div>
                </Button>
                <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />

                <Dropdown.Menu>
                    <Dropdown.Item href="#account">
                        Minha conta
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    )
}

export default LeftSideMenu