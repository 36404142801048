import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Master from './pages/master'
import Login from './pages/login'

import reportWebVitals from './reportWebVitals'

import './index.scss'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-quill/dist/quill.snow.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { AuthProvider } from './services/AuthContext'
import PrivateRoute from './services/PrivateRoute'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <AuthProvider>
      <Routes>  
        <Route path="/" element={<PrivateRoute />}>
          <Route path='/' element={<Master />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </AuthProvider>
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()