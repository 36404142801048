import React, { useState } from 'react'
import ImageUploading from 'react-images-uploading'

import { XCircle } from 'react-bootstrap-icons'

const ImageUploadingComponent = ({ data }) => {
  const [images, setImages] = useState([])
  const maxNumber = 69

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <ImageUploading
    multiple
    value={images}
    onChange={onChange}
    maxNumber={maxNumber}
    dataURLKey="data_url"
    >
    {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
    }) => (
        // write your building UI
        <div className="upload__image-wrapper">
        <div
            className='uploadImage'
            style={isDragging ? { color: 'red' } : undefined}
            onClick={onImageUpload}
            {...dragProps}
        >
            Clique ou arraste aqui
        </div>
        &nbsp;
        <button onClick={onImageRemoveAll} style={{ display: 'none' }}>Remove all images</button>
        {imageList.map((image, index) => (
            <div key={index} className="image-item">
            <img src={image['data_url']} alt="" width="100" />
            <div className="image-item__btn-wrapper">
                <button onClick={() => onImageUpdate(index)} style={{ display: 'none' }}>Update</button>
                <div className='remove-item' onClick={() => onImageRemove(index)}><XCircle /></div>
            </div>
            </div>
        ))}
        </div>
    )}
    </ImageUploading>
  );
}

export default ImageUploadingComponent