import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TooltipComponent = ({ data }) => {
  return (
    <OverlayTrigger
      key={data.id}
      placement={data.placement}
      overlay={
        <Tooltip id={data.id}>
          {data.content}
        </Tooltip>
      }
      >
      {data.icon}
    </OverlayTrigger>
  )
}

export default TooltipComponent