import React from 'react'
import { Accordion } from 'react-bootstrap'
import SlideImages from './slideImages'
import Highlights from './highlights'
import Photos from './photos'

const WebsiteEdit = ({ data }) => {
  return (
    <Accordion className="accordion-light">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Slide de imagens</Accordion.Header>
        <Accordion.Body>
          <SlideImages />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Destaques</Accordion.Header>
        <Accordion.Body>
          <Highlights />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Fotos</Accordion.Header>
        <Accordion.Body>
          <Photos />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default WebsiteEdit