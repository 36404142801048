import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import TitleWithIcon from '../components/titleWithIcon'

import { House, HourglassSplit, Trophy } from 'react-bootstrap-icons'

import { LineChart } from '@mui/x-charts/LineChart'
import { BarChart } from '@mui/x-charts/BarChart'


const Home = () => {
  const homeData = [40, 30, 20, 27, 18, 23, 34]
  const sobreData = [24, 13, 98, 39, 48, 38, 43]
  const lojasData = [21, 25, 65, 15, 84, 45, 12]
  const servicosData = [96, 45, 85, 21, 45, 68, 47]
  const contatoData = [65, 45, 12, 85, 41, 12, 9]
  const xLabels = [
    '01/09',
    '02/09',
    '03/09',
    '04/09',
    '05/09',
    '06/09',
    '07/09',
  ]

  return (
    <>
      <Row className='mt-10'>
        <Col className='pl-10 pr-10'>
          <div className='p-10 box-default'>
            <TitleWithIcon data={{ icon: <House />, title: 'Página inicial' }} />
          </div>
        </Col>
      </Row>
      <Row className='mt-10'>
        <Col lg={4} className='pl-10 pr-5'>
          <div className='p-10 box-default'>
            <Row>
              <Col>
                <TitleWithIcon data={{ icon: <HourglassSplit />, title: 'Histórico de visitas por páginas', bottomBar: true }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <LineChart
                  width={500}
                  height={300}
                  series={[
                    { data: homeData, label: '/home' },
                    { data: sobreData, label: '/sobre' },
                    { data: lojasData, label: '/lojas' },
                    { data: servicosData, label: '/servicos' },
                    { data: contatoData, label: '/contato' },
                  ]}
                  xAxis={[{ scaleType: 'point', data: xLabels }]}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={4} className='pl-5 pr-5'>
          <div className='p-10 box-default'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <TitleWithIcon data={{ icon: <Trophy />, title: 'Páginas mais acessadas', bottomBar: true }} />
              </Col>
              <Col>
                <BarChart
                  xAxis={[{ scaleType: 'band', data: ['Home', 'Sobre', 'Lojas', 'Serviços', 'Contato'] }]}
                  series={[{ data: [45, 35, 24, 30, 15] }]}
                  width={500}
                  height={300}
                  barLabel="value"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Home