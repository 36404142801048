import React, { useState } from 'react'
import { Row, Col, Tab, Container } from 'react-bootstrap'
import CollapseButton from '../components/collapseButton'
import LeftSideMenu from '../components/leftSideMenu'

import Home from './home'
import Pages from './pages'
import Users from './users'
import Settings from './settings'
import Account from './account'

import ErrorBoundary from '../components/errorBoundary'

const Master = ({ logado }) => {
  const [open, setOpen] = useState(true)

  const data = [
    {
      key: '#home',
      content: <Home />
    },
    {
      key: '#pages',
      content: <Pages />
    },
    {
      key: '#users',
      content: <Users />
    },
    {
      key: '#settings',
      content: <Settings />
    },
    {
      key: '#account',
      content: <Account />
    }
  ]

  const toggleOpen = () => setOpen(!open)

  return (
    <ErrorBoundary>
      <Container fluid>
        <Row>
          <Col>
            <CollapseButton open={open} toggleOpen={toggleOpen} />
            <Tab.Container id="list" defaultActiveKey="#home">
              <Row>
                <LeftSideMenu tamanho={ open ? 2 : 0 } />
                <Col xs={ open ? 10 : 12}>
                  <Tab.Content className={ open ? '' : 'conteudo-recolhido' }>
                    {data.map((element, index) => (
                      <Tab.Pane key={index} eventKey={element.key}>
                        {element.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  )
}

export default Master