import React from 'react'
import BottomBar from './bottomBar'

const TitleWithIcon = ({ data }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { data.icon }
        <span className='lexend' style={{ marginLeft: '8px' }}>
          { data.title }
        </span>
      </div>
      { data.bottomBar ? <BottomBar /> : ''}
    </>
  )
}

export default TitleWithIcon