import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Gear } from 'react-bootstrap-icons'

const Settings = () => {

  return (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <Row>
        <Col className='component-edit lexend' style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
            <div style={{ fontSize: '25px', display: 'flex', alignItems: 'center', color: '#495057' }}>
              <Gear />
              <label className='lexend-500' style={{ marginLeft: '10px'}}>Configurações</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            Aqui você poderá definir várias configurações relacionadas ao seu site.
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Settings