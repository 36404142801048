import React, { useContext, useState } from 'react'
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
// import { login } from '../services/authenticate'
import { AuthContext } from '../services/AuthContext'

const Login = () => {

  const { login } = useContext(AuthContext)
  const navigate = useNavigate()
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async () => {
    if (!userEmail || !userPassword) {
      setLoginError(true)
      return
    }

    setIsLoading(true)
    setLoginError(false)

    try {
      const logged = await login(userEmail, userPassword, navigate)
      if (logged) {
        localStorage.setItem('token', 'TESTE')
        navigate('/')
      }
    } catch (error) {
      console.error('Erro ao tentar realizar o login: ', error)
      setLoginError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container className="d-flex align-items-center justify-content-center text-center" style={{ height: '100vh' }}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Form style={{ padding: '20px' }}>
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    placeholder="E-mail"
                    aria-label="E-mail"
                    type="text"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: '20px' }}>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    placeholder="Senha"
                    aria-label="Senha"
                    type="password"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="success"
                  onClick={handleLogin}
                  style={{ marginTop: '20px', width: '100%' }}
                >
                  Entrar
                </Button>{' '}
                <Alert className={loginError ? '' : 'visually-hidden'} variant='danger' style={{ marginTop: '20px' }}>
                  Dados incorretos
                </Alert>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login