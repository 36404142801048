import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { PersonCircle } from 'react-bootstrap-icons'

const Account = () => {

  return (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <Row>
          <Col className='component-edit lexend' style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
            <div style={{ fontSize: '25px', display: 'flex', alignItems: 'center', color: '#495057' }}>
              <PersonCircle />
              <label className='lexend-500' style={{ marginLeft: '10px'}}>Minha conta</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            Nessa página você pode ver as configurações relacionadas a sua conta, dados do seu cadastro etc.
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Account