import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

const CollapseButton = ({ open, toggleOpen }) => {
    return (
        <div
            id='botao'
            onClick={toggleOpen}
            className={`botao-menu ${open ? 'botao-expandido' : 'botao-recolhido'} `}
        >
            { open ? <ChevronLeft /> : <ChevronRight />}
        </div>
    )
}

export default CollapseButton