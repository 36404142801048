import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { PersonGear } from 'react-bootstrap-icons'

const Users = () => {

  return (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <Row>
          <Col className='component-edit lexend' style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
            <div style={{ fontSize: '25px', display: 'flex', alignItems: 'center', color: '#495057' }}>
              <PersonGear />
              <label className='lexend-500' style={{ marginLeft: '10px'}}>Usuários</label>
            </div>
          </Col>
        </Row>
        <Row>
            <Col>
                Página para cadastrar novos usuários para acessar o painel administrativo e gerenciar usuários cadastrados.
            </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Users