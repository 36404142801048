import React from 'react'
import { Row, Col, DropdownButton, Dropdown, Form } from 'react-bootstrap'

import TitleWithIcon from '../components/titleWithIcon'
import BottomBar from '../components/bottomBar'
import TooltipComponent from '../components/tooltipComponent'
import WebsiteEdit from '../components/websiteEdit'

import { FileEarmark, Gear, InfoCircle } from 'react-bootstrap-icons'

const Pages = () => {

  const options = [
    { name: 'Home' },
    { name: 'Sobre' },
    { name: 'Nossas Unidades' },
    { name: 'O que tem na Elefantinho' },
    { name: 'Contato' }
  ]

  return (
    <>
      <Row className='mt-10'>
        <Col className='pl-10 pr-10'>
          <div className='p-10 box-default'>
            <Row>
              <Col lg={1}>
                <TitleWithIcon data={{ icon: <FileEarmark />, title: 'Páginas' }} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className='mt-10'>
        <Col lg={4} className='pl-10 pr-5'>
          <div className='p-10 box-default'>
            <Row>
              <Col>
                <TitleWithIcon data={{ icon: <Gear />, title: 'Configurações', bottomBar: true }} />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className='text-center' style={{ marginBottom: '10px' }}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Selecione uma página para editar"
                  variant="light"
                  style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                >
                  {options.map((element,index) => (
                    <Dropdown.Item key={index} href="#">
                      {element.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <BottomBar />
              </Col>
              <Col lg={6}>
                <div className='edit-item'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Check
                      type="switch"
                      id="switch-home"
                      label="Página inicial"
                    />
                    <TooltipComponent data={{ id: 1, placement: 'top', content: 'Teste', icon: <InfoCircle style={{ marginLeft: '10px' }} />}} />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className='edit-item'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Check
                      type="switch"
                      id="switch-menu"
                      label="Exibir no menu"
                    />
                    <TooltipComponent data={{ id: 1, placement: 'top', content: 'Teste', icon: <InfoCircle style={{ marginLeft: '10px' }} />}} />
                  </div>
                </div>
              </Col>
              <Col lg={12} style={{ marginTop: '10px' }}>
                <div className='edit-item'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Group className="mb-3 w-100" controlId="exampleForm.ControlInput1">
                      <Form.Label>Nome da página</Form.Label>
                      <TooltipComponent data={{ id: 1, placement: 'top', content: 'Teste', icon: <InfoCircle style={{ marginLeft: '10px' }} />}} />
                      <Form.Control type="text" placeholder="Digite o nome da página" />
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col lg={12} style={{ marginTop: '10px' }}>
                <div className='edit-item'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Group className="mb-3 w-100" controlId="exampleForm.ControlInput1">
                      <Form.Label>Endereço da página</Form.Label>
                      <TooltipComponent data={{ id: 1, placement: 'top', content: 'Teste', icon: <InfoCircle style={{ marginLeft: '10px' }} />}} />
                      <Form.Control type="text" placeholder="Digite o endereço da página" />
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8} className='pl-5 pr-10'>
          <WebsiteEdit />
          <div className='p-10 box-site-edit' style={{ display: 'none' }}>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Pages