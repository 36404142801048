import React from 'react'
import { Form } from 'react-bootstrap'

const FormComponent = ({ data }) => {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Título</Form.Label>
        <Form.Control type="text" placeholder="Digite o título do destaque" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Texto</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Ícone</Form.Label>
        <Form.Control type="text" placeholder="Escolha um ícone" />
      </Form.Group>
    </Form>
  )
}

export default FormComponent